import { StorageService } from './services/storage';

/**
 * Toggles a floating button when the scroll height is met.
 */
(function () {
  $(document).ready(function () {
    const storageService = new StorageService();
    var WIDTH_CUT_OFF = 640;
    var $floatingButton = $('[data-std-floating]');
    var scrollHeight =
      $('.header-bar').outerHeight() + $('.header').outerHeight() + $('.primary.section').outerHeight();

    if ($(window).width() < WIDTH_CUT_OFF) {
      $(window).on('scroll', function () {
        const stdCenter = storageService.psc;
        if ($(this).scrollTop() > scrollHeight && Object.keys(stdCenter).length === 0) {
          $floatingButton.fadeIn('medium');
        } else {
          $floatingButton.fadeOut('medium');
        }
      });
    }
  });
})();
